import { BrowserRouter } from "react-router-dom"
import AdminRoutes from "./routes/AdminRoutes"
import ConsumerRoutes from "./routes/ConsumerRoutes"
import globals from "./constants/globals"
import useGetMyPermissions from "./hooks/useGetMyPermissions"
import GlobalStyles from "./components/styled/GlobalStyles"
import FullScreenSpinner from "./components/styled/FullScreenSpinner.styled"
import ScrollToTop from "./components/Layout/ScrollToTop"

const App = () => {
  const { loading } = useGetMyPermissions()

  if (loading) {
    return <FullScreenSpinner />
  }

  return (
    <BrowserRouter>
      {/* Scrolls To Top on Location Change */}
      <ScrollToTop />
      <GlobalStyles />
      {globals.IS_ADMIN ? <AdminRoutes /> : <ConsumerRoutes />}
    </BrowserRouter>
  )
}

export default App
