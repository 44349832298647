import { Form, Input } from "antd"

const ConfirmPassword = () => {
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: "Please confirm your Password",
        },
        ({ getFieldValue }) => ({
          validator: async (_, value) => {
            if (!value || getFieldValue("pwd") === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error("Passwords do not match"))
          },
        }),
      ]}
      dependencies={["pwd"]}
      name="confirmPwd"
      label="Re-enter password"
    >
      <Input.Password />
    </Form.Item>
  )
}

export default ConfirmPassword
