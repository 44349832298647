import { Link } from "react-router-dom"
import { AdminPaths } from "../../constants/paths"
import { AdminPermissions } from "../../constants/permissions"
import DropdownNavbar from "./DropdownNavbar"

const { VIEW_DEALER_UPLOADER } = AdminPermissions
const { DEALER_UPLOADER_PATH } = AdminPaths

const items = [
  {
    label: <Link to={DEALER_UPLOADER_PATH}>Dealer Uploader</Link>,
    permission: VIEW_DEALER_UPLOADER,
    key: "1",
  },
]

type UploaderNavItemsProps = {
  toggleCollapse: () => void
}

const UploaderNavItems = ({ toggleCollapse }: UploaderNavItemsProps) => {
  return (
    <DropdownNavbar
      items={items}
      label="Uploader"
      toggleCollapse={toggleCollapse}
    />
  )
}

export default UploaderNavItems
