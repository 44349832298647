import globals from "../constants/globals";
import { Events } from "../constants/HttpInterceptors";

const getAuthToken = (): string | null => localStorage.getItem("auth");
const getRefreshToken = (): string | null => localStorage.getItem("refreshToken");
const removeAuthToken = (): void => localStorage.removeItem("auth");
const INVALID_SESSION_TOKEN = "INVALID_SESSION_TOKEN";
const tokenLSName = 'auth';
const redirectPath = '/';

const setAuthToken = (auth: any): void =>{
  localStorage.setItem("auth", auth.token);
  localStorage.setItem("refreshToken", auth.refresh_token);
}

let logOutTime: ReturnType<typeof setTimeout>;

const {AUTO_LOGOUT_TIME} = globals

const setTime = () => {
  logOutTime = setTimeout(() => {
    if (window.location.pathname !== "/") {
      window.location.href = "/";
      localStorage.clear();
    }
  }, AUTO_LOGOUT_TIME);
};

const resetTime = () => {
  clearTimeEvent();
  setTime();
};

const clearTimeEvent = () => {
  if (!!logOutTime) {
    clearTimeout(logOutTime);
  }
};

const autoLogout = () => {
  let events = Events;
  for (const ev of events) {
    window.addEventListener(ev, (e) => {
      resetTime();
    });
  }
  setTime();
};


export { getAuthToken, setAuthToken, removeAuthToken, autoLogout, getRefreshToken, INVALID_SESSION_TOKEN, tokenLSName, redirectPath }
