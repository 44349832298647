import { Form, FormInstance } from "antd"
import { PasswordInput } from "antd-password-input-strength"

type PasswordProps = {
  form: FormInstance
}

const Password = ({ form }: PasswordProps) => {
  return (
    <Form.Item
      name="pwd"
      label="Create Password"
      rules={[
        {
          required: true,
          message: "Please enter a Password",
        },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,32}$/,
          message:
            "Minimum 8 characters, one uppercase letter and one special character",
        },
      ]}
    >
      <PasswordInput autoComplete="new-password" />
    </Form.Item>
  )
}

export default Password
