import { Form, Input } from "antd"

const DealerNameNew = () => {
  return (
    <Form.Item name="dealerShopName" label="Dealer Name">
      <Input autoComplete="new-password" />
    </Form.Item>
  )
}

export default DealerNameNew
