import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import globals from "../../constants/globals"
import { useAuthStatus } from "../../hooks/useAuthStatus"
import Spinner from "../Spinner"
import Container from "../styled/Container.styled"
import Footer from "./Footer"
import Header from "./Header"
import UserStats from "./UserStats"
import ClaimSaleButton from "../ClaimSale/ClaimSaleButton"

const { IS_ADMIN } = globals

const Layout = () => {
  const { loggedIn } = useAuthStatus()

  return (
    <>
      <Header />
      {loggedIn && !IS_ADMIN ? (
        <>
          <UserStats />
          <ClaimSaleButton />
        </>
      ) : null}
      <Suspense
        fallback={
          <Container>
            <Spinner />
          </Container>
        }
      >
        <Outlet />
      </Suspense>
      <Footer />
    </>
  )
}

export default Layout
