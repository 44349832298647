import { GET_ADDRESS_REPORT } from "../../graphql/queries"
import Reports from "./reports/Reports"

const checkBoxList = [
  {
    value: "firstName",
    label: "First Name",
  },
  {
    value: "lastName",
    label: "Last Name",
  },
  {
    value: "jobTitle",
    label: "Job Title",
  },
  {
    value: "workEmail",
    label: "Email",
  },
  {
    value: "street1",
    label: "Updated Address Line 1",
  },
  {
    value: "street2",
    label: "Updated Address Line 2",
  },
  {
    value: "city",
    label: "Updated City",
  },
  {
    value: "state",
    label: "Updated State",
  },
  {
    value: "zip",
    label: "Updated ZIP",
  },
  {
    value: "addressUpdatedAt",
    label: "Date of Change",
  },
  {
    value: "shopName",
    label: "Dealership",
  },
]

const ChangeAddressReport = () => {
  return (
    <Reports
      checkBoxList={checkBoxList}
      query={GET_ADDRESS_REPORT}
      title="Change Address Report"
      label="Date of Change:"
      showDateRange={false}
    />
  )
}

export default ChangeAddressReport
