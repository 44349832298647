import { gql } from "@apollo/client"

// Consumer Side
const GET_ALL_DEALERS = gql`
  query Dealers {
    dealers {
      _id
      shopName
      streetAddress
      city
      state
      zip
    }
  }
`

const GET_ALL_PRODUCTS = gql`
  query Products {
    products {
      _id
      productDescription
      productID
    }
  }
`

const GET_MY_PROFILE_DETAILS = gql`
  query EnrolmentByUserID {
    enrolmentByUserID {
      _id
      firstName
      lastName
      street1
      street2
      city
      state
      zip
      phone
      workEmail
    }
  }
`

// Common
const GET_MY_PERMISSIONS = gql`
  query UserPermissions {
    userPermissions
  }
`

const GET_CURRENT_INCENTIVES = gql`
  query ListProductsWithActiveRewardsWithPagination(
    $page: Int
    $limit: Int
    $month: Int
    $year: Int
  ) {
    listProductsWithActiveRewardsWithPagination(
      page: $page
      limit: $limit
      month: $month
      year: $year
    ) {
      productListWithActiveRewards {
        _id
        productID
        productImage
        productDescription
        rewards {
          _id
          rewardType
          rewardValue
          minProductsSold
          maxProductsSold
          startDate
          endDate
          isRetroActive
        }
      }
      totalPages
      totalDocs
      limit
      currentPage
    }
  }
`

const GET_ANNOUNCEMENTS = gql`
  query ListAnnouncementsWithPagination($page: Int, $limit: Int) {
    listAnnouncementsWithPagination(page: $page, limit: $limit) {
      announcements {
        _id
        title
        body
        image
      }
      totalPages
      totalDocs
      limit
      currentPage
    }
  }
`

const GET_HEADER = gql`
  query GetHeader {
    getHeader {
      content
      _id
    }
  }
`

// Admin Side
const GET_ALL_ENROLLMENTS = gql`
  query Enrolments($page: Int, $limit: Int) {
    enrolments(page: $page, limit: $limit) {
      enrolments {
        _id
        dealerId {
          _id
          shopName
        }
        firstName
        lastName
        goesByName
        jobTitle
        street1
        street2
        city
        state
        zip
        country
        phone
        workEmail
        smsOptIn
        documentUrl
      }
    }
  }
`

const GET_CLAIMS = gql`
  query ListClaimsWithPagination(
    $searchTerm: String
    $page: Int
    $limit: Int
    $status: String
    $startDate: String
    $endDate: String
    $mode: String
    $fields: [String]
  ) {
    listClaimsWithPagination(
      searchTerm: $searchTerm
      page: $page
      limit: $limit
      status: $status
      startDate: $startDate
      endDate: $endDate
      mode: $mode
      fields: $fields
    ) {
      claims {
        awardValue
        bonusValue
        brand
        dealerName
        firstName
        id
        lastName
        productIdManual
        productName
        productPurchased
        quantity
        sku
        status
        productId
        orderId
        enrolmentId
        dateOfSale
        dealerId
        declinedReasons
        productDescription
        createdAt
        claimDocumentUrl
        statusUpdatedAt
        isProcessed
        approvedOrDeclinedByName
        bonusCriteria
      }
      currentPage
      limit
      totalDocs
      totalPages
      url
    }
  }
`

const GET_CLAIM_BY_ID = gql`
  query ClaimById($claimByIdId: String!) {
    claimById(id: $claimByIdId) {
      id
      productId
      productIdManual
      productName
      dateOfSale
      enrolmentId
      firstName
      lastName
      dealerId
      dealerName
      quantity
      sku
      productPurchased
      brand
      awardValue
      bonusValue
      status
      statusUpdatedAt
      orderId
      productDescription
      enrolmentEmail
      declinedReasons
      isProcessed
      createdAt
      claimDocumentUrl
    }
  }
`

const GET_ENROLLMENT = gql`
  query Enrolments(
    $mode: String
    $startDate: String
    $endDate: String
    $fields: [String]
    $limit: Int
  ) {
    enrolments(
      mode: $mode
      startDate: $startDate
      endDate: $endDate
      fields: $fields
      limit: $limit
    ) {
      url
    }
  }
`

const GET_ADDRESS_REPORT = gql`
  query ChangeAddressReport(
    $mode: String
    $startDate: String
    $endDate: String
    $fields: [String]
  ) {
    changeAddressReport(
      mode: $mode
      startDate: $startDate
      endDate: $endDate
      fields: $fields
    ) {
      url
    }
  }
`

const GET_USER_STAT = gql`
  query UserStats {
    userStats {
      dealerName
      earnedLastMonth
      earnedThisMonth
      earnedYearToDate
      email
      name
      isSSNEntered
    }
  }
`

const GET_USERS_WITH_PAGINATION = gql`
  query UsersWithPagination($page: Int, $limit: Int, $searchQuery: String) {
    usersWithPagination(searchQuery: $searchQuery, page: $page, limit: $limit) {
      users {
        _id
        name
        userName
        userEmail
        userType
        authRegionID
        authDistrictID
        authDealerID
        createdAt
        registeredDate
        isActive
        roleData {
          _id
          roleCode
          roleName
        }
      }
      totalPages
      totalDocs
      limit
      currentPage
    }
  }
`

const GET_USER_ROLES = gql`
  query Roles {
    roles {
      createdAt
      _id
      roleCode
      roleName
    }
  }
`

const RESEND_EMAIL_TO_USER = gql`
  query Query($email: String!) {
    resendEnrollmentMailToUser(email: $email)
  }
`

const GET_INCENTIVE_BY_ID = gql`
  query ProductByID($productId: ID!) {
    productByID(productID: $productId) {
      _id
      productID
      productDescription
      rewards {
        rewardType
        rewardValue
        minProductsSold
        maxProductsSold
        startDate
        endDate
        isRetroActive
      }
    }
  }
`

const GET_TOKEN_FROM_REFRESH_TOKEN = gql`
  query Query($refreshToken: String) {
    refreshSessionToken(refreshToken: $refreshToken)
  }
`

const CHECK_PRODUCT_HAS_CLAIM = gql`
  query ProductHasClaim($productId: ID!) {
    productHasClaim(productId: $productId)
  }
`

const GET_ALL_ORDERS = gql`
  query AllOrders($page: Int, $limit: Int, $searchQuery: String) {
    allOrders(page: $page, limit: $limit, searchQuery: $searchQuery) {
      currentPage
      limit
      orders {
        _id
        createdAt
        documentUrl
        filename
        funded
        fundedAt
        orderTotal
        status
        orderId
      }
      totalDocs
      totalPages
    }
  }
`

const FUND_ORDER = gql`
  query Query($id: ID!) {
    fundOrderById(id: $id)
  }
`

const GET_REGIONS_BY_USERID = gql`
  query RegionsByUserID {
    regionsByUserID {
      regionId
      regionName
    }
  }
`

const GET_DISTRICTS_BY_REGION_ID = gql`
  query DistrictsByListOfRegionsIds($regionsIds: [String]) {
    districtsByListOfRegionsIds(regionsIds: $regionsIds) {
      districtId
      districtName
      regionId
    }
  }
`

const GET_DEALERS_BY_DISTRICT_ID = gql`
  query DealersByDistrictsIds($districtsIds: [String]) {
    dealersByDistrictsIds(districtsIds: $districtsIds) {
      _id
      shopName
      contactName
      contactRole
      contactEmail
      streetAddress
      city
      state
      zip
      phoneNumber
      initalContact
      kryptoniteLightDealer
      notes
      additionalNotes
      receiptLink
      isRegistered
      cronEmailCount
      registeredAt
    }
  }`

const GET_EMAIL_BY_ID = gql`
  query EmailsByEnrolmentUserId($page: Int, $limit: Int) {
  emailsByEnrolmentUserId(page: $page, limit: $limit) {
    currentPage
    emailsWithEmailRecipient {
      _id
      enrolmentId
      sentDate
      isSent
      createdAt
      emailId {
        _id
        fromAddress
        subject
        messageBody
        attachmentsUrls
        createdAt
      }
    }
    limit
    totalDocs
    totalPages
  }
}`

const GET_EMAIL_BY_USER_ID = gql`
query EmailsByUserId($page: Int, $limit: Int) {
  emailsByUserId(page: $page, limit: $limit) {
    totalPages
    totalDocs
    limit
    currentPage
    emails {
      _id
      fromAddress
      subject
      messageBody
      attachmentsUrls
      createdAt
    }
  }
}`

const GET_MESAAGE = gql`
query MessagesByEnrolmentUserId($page: Int, $limit: Int) {
  messagesByEnrolmentUserId(page: $page, limit: $limit) {
    totalPages
    totalDocs
    limit
    currentPage
    messagesWithMessageRecipient {
      _id
      enrolmentId
      sentDate
      isSent
      createdAt
      messageId {
        _id
        fromPhoneNumber
        messageBody
        createdAt
      }
    }
  }
}`

const GET_MESSAGE_BY_USER_ID = gql`
query MessagesByUserId($page: Int, $limit: Int) {
  messagesByUserId(page: $page, limit: $limit) {
    totalPages
    totalDocs
    limit
    currentPage
    messages {
      _id
      fromPhoneNumber
      messageBody
      createdAt
    }
  }
}`

export {
  GET_ALL_ENROLLMENTS,
  GET_MY_PROFILE_DETAILS,
  GET_ALL_DEALERS,
  GET_MY_PERMISSIONS,
  GET_CURRENT_INCENTIVES,
  GET_ALL_PRODUCTS,
  GET_CLAIMS,
  GET_CLAIM_BY_ID,
  GET_ENROLLMENT,
  GET_USER_STAT,
  GET_USERS_WITH_PAGINATION,
  GET_USER_ROLES,
  RESEND_EMAIL_TO_USER,
  GET_ANNOUNCEMENTS,
  GET_INCENTIVE_BY_ID,
  GET_HEADER,
  GET_TOKEN_FROM_REFRESH_TOKEN,
  CHECK_PRODUCT_HAS_CLAIM,
  GET_ALL_ORDERS,
  FUND_ORDER,
  GET_REGIONS_BY_USERID,
  GET_DISTRICTS_BY_REGION_ID,
  GET_DEALERS_BY_DISTRICT_ID,
  GET_EMAIL_BY_ID,
  GET_EMAIL_BY_USER_ID,
  GET_ADDRESS_REPORT,
  GET_MESAAGE,
  GET_MESSAGE_BY_USER_ID
}
