import { Form, Alert, Divider, Checkbox, UploadFile } from "antd"
import { useState } from "react"
import {
  CREATE_ENROLLMENT,
  VALIDATE_ADDRESS,
  VALIDATE_ENROLLMENT_FORM,
} from "../../graphql/mutations"
import { useMutation } from "@apollo/client/react"
import Button, { SecondaryButton } from "../../components/styled/Button.styled"
import FileUpload from "../../components/FileUpload"
import globals from "../../constants/globals"
import EnrollmentSuccess from "../../components/EnrollmentForm/EnrollmentSuccess"
import HeroSection from "../../components/styled/HeroSection.styled"
import Password from "../../components/Form/Password"
import ConfirmPassword from "../../components/Form/ConfirmPassword"
import Spinner from "../../components/Spinner"
import Footer from "../../components/Layout/Footer"
import Header from "../../components/Layout/Header"
import LegalFirstName from "../../components/EnrollmentForm/LegalFirstName"
import LegalLastName from "../../components/EnrollmentForm/LegalLastName"
import GoesByName from "../../components/EnrollmentForm/GoesByName"
import JobTitle from "../../components/EnrollmentForm/JobTitle"
import WorkEmail from "../../components/EnrollmentForm/WorkEmail"
import PhoneNumber from "../../components/EnrollmentForm/PhoneNumber"
import Address2 from "../../components/EnrollmentForm/Address2"
import City from "../../components/EnrollmentForm/City"
import State from "../../components/EnrollmentForm/State"
import ZIP from "../../components/EnrollmentForm/ZIP"
import DealerZIP from "../../components/EnrollmentForm/DealerZIP"
import DealerState from "../../components/EnrollmentForm/DealerState"
import DealerCity from "../../components/EnrollmentForm/DealerCity"
import DealerNameExisting from "../../components/EnrollmentForm/DealerNameExisting"
import DealerNameNew from "../../components/EnrollmentForm/DealerNameNew"
import AddressAutocomplete from "../../components/EnrollmentForm/AddressAutocomplete"
import useGetProgramHeader from "../../hooks/useGetProgramHeader"
import CountryCode from "../../components/EnrollmentForm/CountryCode"

const { PROGRAM_NAME } = globals

const EnrollmentForm = () => {
  const [checked, setChecked] = useState(false)
  const [isNewDealer, setIsNewDealer] = useState(false)
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [blockSubmit, setBlockSubmit] = useState<boolean>(false)
  const [countryCod, setCountryCode] = useState<string>("+1")
  const [isDealerAddressValidated, setIsDealerAddressValidated] = useState(true)
  const [isPersonalAddressValidated, setIsPersonalAddressValidated] =
    useState(true)

  const [form] = Form.useForm()

  // Mutation for creating an enrollment
  const [
    enrollment,
    {
      data: successEnrollment,
      loading: creatingEnrollment,
      error: errorEnrollment,
      reset,
    },
  ] = useMutation(CREATE_ENROLLMENT)
  // Mutation for validating an enrollment
  const [
    validateEnrollment,
    { reset: validateReset, error: validateError, loading: validateLoading },
  ] = useMutation(VALIDATE_ENROLLMENT_FORM)
  // Mutation for validating addresses
  const [
    validateAddress,
    { reset: validateReset2, error: validateError2, loading: validateLoading2 },
  ] = useMutation<{ validateAddress: "true" | "false" }>(VALIDATE_ADDRESS)

  const { header } = useGetProgramHeader()

  const onFinish = (values: any) => {
    reset()
    validateReset()
    validateReset2()
    if (blockSubmit) {
      return
    }
    if (!readOnly) {
      return validateAndToggleReadOnly(values.workEmail)
    }

    const forApi = {
      enrolment: {
        ...values,
        smsOptIn: checked,
        document: values.document?.fileList.map(
          (file: UploadFile) => file.originFileObj
        ),
        confirmPwd: undefined,
        phone: countryCod + values.phone
      },
    }
    enrollment({
      variables: forApi,
      onError: toggleReadOnly,
    })
  }

  const toggleReadOnly = (): void => setReadOnly((val) => !val)
  // eslint-disable-next-line
  const toggleExistingNewDealer = () => {
    setIsNewDealer((val) => !val)
    form.resetFields([
      "dealerId",
      "dealerStreetAddress",
      "dealerState",
      "dealerCity",
      "dealerZip",
      "dealerShopName",
    ])
  }

  const validateAndToggleReadOnly = (workEmail: string) => {
    const dealerAddressVariables = {
      dealerStreetAddress: form.getFieldValue("dealerStreetAddress"),
      dealerCity: form.getFieldValue("dealerCity"),
      dealerState: form.getFieldValue("dealerState"),
      dealerZip: form.getFieldValue("dealerZip"),
      secondary: form.getFieldValue("secondary"),
    }

    const personalAddressVariables = {
      dealerStreetAddress: form.getFieldValue("street1"),
      dealerCity: form.getFieldValue("city"),
      dealerState: form.getFieldValue("state"),
      dealerZip: form.getFieldValue("zip"),
      secondary: form.getFieldValue("street2"),
    }

    // Validate Personal Address
    validateAddress({
      variables: personalAddressVariables,
      onCompleted: (data) => {
        setIsPersonalAddressValidated(data.validateAddress === "true")
        // Validate Dealer Address if New Dealer
        if (isNewDealer && data.validateAddress === "true") {
          validateAddress({
            variables: dealerAddressVariables,
            onCompleted: (data) => {
              setIsDealerAddressValidated(data.validateAddress === "true")
              // Validate Enrollment, basically for duplicate email
              if (data.validateAddress === "true") {
                validateEnrollment({
                  variables: { workEmail },
                  onCompleted: () => {
                    window.scrollTo(0, 500)
                    toggleReadOnly()
                  },
                })
              }
            },
          })
        } else {
          // Validate Enrollment, same as nested one above
          if (data.validateAddress === "true") {
            validateEnrollment({
              variables: { workEmail },
              onCompleted: () => {
                window.scrollTo(0, 500)
                toggleReadOnly()
              },
            })
          }
        }
      },
    })
  }

  if (successEnrollment) {
    return (
      <>
        <Header />
        <div className="vh-150">
          <EnrollmentSuccess />
        </div>
        <Footer />
      </>
    )
  }

  return (
    <>
      <Header />
      <div className="vh-250">
        <HeroSection
          mutedText={PROGRAM_NAME}
          heading="Enroll Now"
          description={header}
        />

        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-11 border p-md-5 p-3">
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                scrollToFirstError
                disabled={readOnly}
                requiredMark={false}
              >
                <h5>1. Dealer Information</h5>

                <div className="row">
                  {/* This has been commented out as asked in https://bestpaymentsolutions.atlassian.net/browse/KCSIP-421 */}
                  {/* <Checkbox
                    onChange={toggleExistingNewDealer}
                    checked={isNewDealer}
                    className="my-3"
                  >
                    New Dealer
                  </Checkbox> */}

                  {isNewDealer ? (
                    <div className="col-12">
                      <DealerNameNew />
                    </div>
                  ) : (
                    <div className="col-12">
                      <DealerNameExisting form={form} />
                    </div>
                  )}

                  <div className="col-12">
                    <AddressAutocomplete
                      form={form}
                      name="dealerStreetAddress"
                      label="Dealer Address"
                      formItemNamesToUpdate={[
                        "none",
                        "dealerCity",
                        "dealerState",
                        "dealerZip",
                      ]}
                      requiredMessage="Please enter a dealer address"
                      disabled={!isNewDealer || readOnly}
                    />
                  </div>

                  <div className="col-sm-6">
                    <DealerCity disabled={!isNewDealer || readOnly} />
                  </div>

                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col">
                        <DealerState disabled={!isNewDealer || readOnly} />
                      </div>
                      <div className="col">
                        <DealerZIP disabled={!isNewDealer || readOnly} />
                      </div>
                    </div>
                  </div>
                </div>

                <Divider />

                <h5>2. Personal Contact Information</h5>

                <div className="row">
                  {[
                    <LegalFirstName />,
                    <LegalLastName />,
                    <GoesByName />,
                    <JobTitle />,
                    <WorkEmail />,
                    //<CountryCode country={(v) => setCountryCode(v)} />,
                    <PhoneNumber />,
                    // For Address 1
                    <AddressAutocomplete
                      form={form}
                      name="street1"
                      label="Address 1"
                      formItemNamesToUpdate={[
                        "street2",
                        "city",
                        "state",
                        "zip",
                      ]}
                      requiredMessage="Please enter an address"
                      disabled={readOnly}
                    />,
                    <Address2 />,
                    <City />,
                  ].map((item, index) => (
                    <div className="col-sm-6" key={index}>
                      {item}
                    </div>
                  ))}

                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col">
                        <State />
                      </div>
                      <div className="col">
                        <ZIP />
                      </div>
                    </div>
                  </div>
                </div>

                <FileUpload setBlockSubmit={setBlockSubmit} maxFileCount={10} />

                <Divider />

                <h5>3. Mobile Phone</h5>

                <Checkbox
                  onChange={(e) => setChecked(e.target.checked)}
                  checked={checked}
                >
                  I want to receive SMS messages regarding this program
                </Checkbox>

                <Divider />

                <h5>4. Create Your Account </h5>

                <div className="row">
                  <div className="col-sm-6">
                    <Password form={form} />
                  </div>
                  <div className="col-sm-6">
                    <ConfirmPassword />
                  </div>
                </div>
              </Form>

              {/* Spinner */}
              <div className="row">
                {(creatingEnrollment ||
                  validateLoading ||
                  validateLoading2) && <Spinner />}
              </div>

              {/* Info Message For Address Validation */}
              {!isDealerAddressValidated && (
                <div className="col-12 mb-3">
                  <Alert
                    type="error"
                    message="Please enter the correct dealer address"
                  />
                </div>
              )}
              {!isPersonalAddressValidated && (
                <div className="col-12 mb-3">
                  <Alert
                    type="error"
                    message="Please enter correct personal address"
                  />
                </div>
              )}

              {/* Error Messages */}
              {errorEnrollment && (
                <div className="col-12 mb-3">
                  <Alert type="error" message={errorEnrollment.message} />
                </div>
              )}
              {validateError && (
                <div className="col-12 mb-3">
                  <Alert type="error" message={validateError.message} />
                </div>
              )}
              {validateError2 && (
                <div className="col-12 mb-3">
                  <Alert type="error" message={validateError2.message} />
                </div>
              )}
              <div className="row justify-content-center mt-3">
                {readOnly && (
                  <div className="col-md-6 mb-3 mb-md-3">
                    <SecondaryButton
                      type="button"
                      onClick={toggleReadOnly}
                      disabled={
                        creatingEnrollment ||
                        validateLoading ||
                        validateLoading2
                      }
                    >
                      Go Back
                    </SecondaryButton>
                  </div>
                )}
                <div className="col-md-6">
                  <Button
                    type="submit"
                    onClick={form.submit}
                    disabled={
                      creatingEnrollment || validateLoading || validateLoading2
                    }
                  >
                    Enroll Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default EnrollmentForm
