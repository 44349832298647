import { Form, Select } from "antd"
import { jobTitles } from "../../constants/enrollmentForm"

const { Option } = Select

const JobTitle = () => {
  return (
    <Form.Item
      rules={[{ required: true, message: "Please enter a job title" }]}
      name="jobTitle"
      label="Job Title"
    >
      <Select virtual={false}>
        {jobTitles.map((jobTitle, index) => (
          <Option key={index} value={jobTitle}>
            {jobTitle}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default JobTitle
