import { Link, useLocation } from "react-router-dom"
import Button from "../styled/Button.styled"
import { ConsumerPaths } from "../../constants/paths"

const { CLAIM_SALE_PATH } = ConsumerPaths

const ClaimSaleButton = () => {
  const { pathname } = useLocation()
  const isClaimSalePage =
    pathname === CLAIM_SALE_PATH || pathname === `${CLAIM_SALE_PATH}/`

  return !isClaimSalePage ? (
    <div className="bg-white d-md-none py-2 sticky-top border-bottom shadow-sm row justify-content-center ">
      <div className="col-6">
        <Link to={CLAIM_SALE_PATH} className="text-black text-decoration-none">
          <Button type="button">Claim a Sale</Button>
        </Link>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ClaimSaleButton
