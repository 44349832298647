import { useState, useEffect } from "react"
import { getAuthToken } from "../utils/auth"

export const useAuthStatus = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [checkingStatus, setCheckingStatus] = useState(true)

  const auth = getAuthToken()
  useEffect(() => {
    setLoggedIn(!!auth)
    setCheckingStatus(false)
  }, [auth])

  return { loggedIn, checkingStatus }
}
