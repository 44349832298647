import { createGlobalStyle } from "styled-components"
import globals from "../../constants/globals"

const { PRIMARY_COLOR } = globals

const GlobalStyles = createGlobalStyle`
  .cursor-pointer {
    cursor: pointer
  }

  .cursor-default {
    cursor: default;
  }
  
  .nav-item {
    margin: 3px;
    &:hover,
    .active {
      background-color: ${PRIMARY_COLOR};
    }
    a {
      font-weight: 600;
    }
    div {
      font-weight: 600;
      padding-left: 5px;
    }
    @media (max-width: 992px) {
      a {
        padding-left: 10px;
      }
    }
  }

  .dropdown-navbar {
    width: 200px;
    li a {
      font-weight: 600;
      text-decoration: none;
    }
  }

  body {
    font-family: 'Armata', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Quantico', sans-serif;
  }

  .custom-link {
    color: #000;
    cursor: pointer;
    text-decoration: underline;
    &:hover, &:focus {
      color: #000;
      text-decoration: underline;
    }
    &:focus {
      outline: 1px black solid;
    }
  }

  /* Edit Claim Modal Styles */
  .edit-claim-modal {
    min-width: 70%;
    @media screen and (max-width: 992px) {
      min-width: 90%;
    }
  }

  /* Announcement Modal Styles */
  .announcement-modal {
    min-width: 60%;
    @media screen and (max-width: 992px) {
      min-width: 90%;
    }
  }
  .react-quill-style {
    .ql-editor {
      min-height: 250px;
    }
    margin-top: 0.5rem;
  }

  .close-icon {
    cursor: pointer;
    font-size: 2rem;
    color: #666;
  }

  /** Layout */
  #root {
    height: 100%;
  }

  .vh-250 {
    min-height: calc(100vh - 250px);
  }

  .vh-200 {
    min-height: calc(100vh - 200px);
  }

  .vh-150 {
    min-height: calc(100vh - 150px);
  }
`

export default GlobalStyles
