import { CommonPaths } from "../constants/paths"
import { CommonPermissions } from "../constants/permissions"
import { IRoute } from "../types/route"
import { lazy } from "react"

const CurrentIncentives = lazy(() => import("../pages/CurrentIncentives"))
const ResourcesFAQ = lazy(() => import("../pages/ResourcesFAQ"))
const ResourcesPR = lazy(() => import("../pages/ResourcesPR"))
const ContactUs = lazy(() => import("../pages/ContactUs"))

const { VIEW_CURRENT_INCENTIVES, VIEW_RESOURCES, VIEW_CONTACTUS } =
  CommonPermissions

const {
  CURRENT_INCENTIVES_PATH,
  RESOURCES_FAQ_PATH,
  RESOURCES_RULES_PATH,
  CONTACTUS_PATH,
} = CommonPaths

const commonRoutes: IRoute[] = [
  {
    element: <CurrentIncentives />,
    path: CURRENT_INCENTIVES_PATH,
    permissionCode: VIEW_CURRENT_INCENTIVES,
  },
  {
    element: <ResourcesFAQ />,
    path: RESOURCES_FAQ_PATH,
    permissionCode: VIEW_RESOURCES,
  },
  {
    element: <ResourcesPR />,
    path: RESOURCES_RULES_PATH,
    permissionCode: VIEW_RESOURCES,
  },
  {
    element: <ContactUs />,
    path: CONTACTUS_PATH,
    permissionCode: VIEW_CONTACTUS,
  },
]

export default commonRoutes
