import styled from "styled-components"

const IncentiveCard = styled.div`
  border: 1px #eee solid;
  border-radius: 10px;
  min-height: 480px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;

  img {
    object-fit: contain;
    width: 100%;
    height: 200px;
    margin-bottom: 16px;
  }

  .ant-divider-horizontal {
    margin: 16px 0 12px 0;
  }
`

const AnnouncementCard = styled.div`
  border: 1px #eee solid;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
  position: relative;

  img {
    object-fit: contain;
    width: 200px;
    height: 200px;
  }

  & > div {
    flex: 1;
    & > h5 {
      font-weight: 600;
    }
    & > span {
      font-size: 1rem;
    }
  }

  .button-container {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    gap: 0.5rem;
  }

  button {
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    color: #666;
  }

  button:hover,
  button:focus {
    outline: 2px solid #666;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export { IncentiveCard, AnnouncementCard }
