import { useQuery } from "@apollo/client"
import { Form, FormInstance, Select } from "antd"
import { useState } from "react"
import { GET_ALL_DEALERS } from "../../graphql/queries"
import { IGetAllDealersResponse } from "../../types/graphqlResponse"

type Props = {
  form: FormInstance
}

const DealerNameExisting = ({ form }: Props) => {
  /** Dealer Options has been given three key value
   * pairs. 'label' is the one user sees, 'value' is ID
   * and it is sent to backend, 'name' is shop name
   * on which the search works
   */

  const [dealerOptions, setDealerOptions] = useState<
    { value: string; label: string; name: string }[] | undefined
  >([])

  const { data: allDealers } = useQuery<IGetAllDealersResponse>(
    GET_ALL_DEALERS,
    {
      onCompleted: (data): void => {
        const mappedData = data.dealers.map((dealer) => ({
          label: `${dealer.shopName} - ${dealer.streetAddress}, ${dealer.city}, ${dealer.state}`,
          name: dealer.shopName,
          value: dealer._id,
        }))
        setDealerOptions(mappedData)
      },
      fetchPolicy: "no-cache",
    }
  )

  const setFormValues = (value: string) => {
    const selectedDealer = allDealers?.dealers.find(
      (dealer) => dealer._id === value
    )
    form.setFieldsValue({
      dealerStreetAddress: selectedDealer?.streetAddress,
      dealerState: selectedDealer?.state,
      dealerCity: selectedDealer?.city,
      dealerZip: selectedDealer?.zip,
    })
    form.validateFields([
      "dealerStreetAddress",
      "dealerState",
      "dealerCity",
      "dealerZip",
    ])
  }

  return (
    <Form.Item
      label="Dealer Name"
      name="dealerId"
      rules={[
        {
          required: true,
          message: "Please enter the dealer name",
        },
      ]}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          input.trim().length > 2
            ? (option?.name ?? "")
                .toLowerCase()
                .includes(input.toLowerCase().trim())
            : true
        }
        options={dealerOptions}
        onSelect={setFormValues}
        virtual={false}
      />
    </Form.Item>
  )
}

export default DealerNameExisting
