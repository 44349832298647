import dayjs from "dayjs"

// This function takes out value from date picker from ant design
const getDate = (date: any) => {
  return dayjs(date).format("YYYY-MM-DD")
}

// To convert date from "YYYY-MM-DD" to "MM/DD/YYYY"
const getFormattedDate = (date?: string) => {
  if (date) {
    const YYYY = date.split("-")[0]
    const MM = date.split("-")[1]
    const DD = date.split("-")[2]

    return MM + "/" + DD + "/" + YYYY
  }
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const formatAmount = (amount?: number) => {
  return amount ? formatter.format(amount) : "$0.00"
}

export { getDate, getFormattedDate, formatAmount }
