import { Link } from "react-router-dom"
import { AdminPaths } from "../../constants/paths"
import { AdminPermissions } from "../../constants/permissions"
import DropdownNavbar from "./DropdownNavbar"

const { EDIT_CLAIM_PATH, INVOICE_ORDER_PATH } = AdminPaths

const { VIEW_EDIT_CLAIM, VIEW_INVOICE_ORDER } = AdminPermissions

const items = [
  {
    label: <Link to={EDIT_CLAIM_PATH}>Edit Sales Claim</Link>,
    permission: VIEW_EDIT_CLAIM,
    key: "1",
  },
  {
    label: <Link to={INVOICE_ORDER_PATH}>Invoice Order</Link>,
    permission: VIEW_INVOICE_ORDER,
    key: "2",
  },
]

type BPSAdminNavItemsProps = {
  toggleCollapse: () => void
}

const BPSAdminNavItems = ({ toggleCollapse }: BPSAdminNavItemsProps) => {
  return (
    <DropdownNavbar
      items={items}
      label="BPS Admin"
      toggleCollapse={toggleCollapse}
    />
  )
}

export default BPSAdminNavItems
