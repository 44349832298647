// @ts-ignore
import { createUploadLink } from "apollo-upload-client"
import { ApolloLink, split } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { ApolloClient, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error";
import globals from "../constants/globals"
import { autoLogout, getAuthToken, getRefreshToken, INVALID_SESSION_TOKEN, redirectPath, tokenLSName } from "./auth"
import { GET_TOKEN_FROM_REFRESH_TOKEN } from "../graphql/queries"




const { GRAPHQL_ENDPOINT, GRAPHQL_SUBSCRIPTION_ENDPOINT } = globals

const httpLink = createUploadLink({
  uri: GRAPHQL_ENDPOINT,
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: GRAPHQL_SUBSCRIPTION_ENDPOINT + "",
  })
)

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  }
})

const errorlink = onError(
  ({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ extensions }) => {
        if (extensions.code === INVALID_SESSION_TOKEN) {
          let refreshToken = getRefreshToken;
          const getToken = new ApolloClient({
            cache: new InMemoryCache(),
            uri: GRAPHQL_ENDPOINT,
          })
          getToken.query({ query: GET_TOKEN_FROM_REFRESH_TOKEN, variables: { refreshToken: refreshToken() } }).then((res: any) => {
            if (!!res.data.refreshSessionToken) {
              window.location.reload();
              localStorage.setItem(tokenLSName, res.data.refreshSessionToken);
            }
          }).catch((errors) => {
            if (!!errors) {
              window.location.href = redirectPath;
              localStorage.clear();
            }
          })
        }
      });
    }
  }
);

const httpLinkErrHandling = ApolloLink.from([errorlink, httpLink]);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  authLink.concat(httpLinkErrHandling)
)



const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
})

autoLogout();

export default client
