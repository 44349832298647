import styled from "styled-components"

const Button = styled.button.attrs(() => ({
  className: "btn",
}))`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  border-color: ${({ theme }) => theme.colors.primaryColor};
  border-radius: 0;
  font-weight: 600;
  &:hover,
  &:focus,
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primaryColor};
    border-color: ${({ theme }) => theme.colors.primaryColor};
  }
  &:focus {
    outline: 1px solid black;
  }
`

const SecondaryButton = styled.button.attrs(() => ({
  className: "btn",
}))`
  width: 100%;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0;
  font-weight: 600;
  &:hover,
  &:focus,
  &:disabled {
    background-color: rgb(0, 0, 0, 0.1);
  }
  &:focus {
    outline: 1px solid black;
  }
`

export default Button

export { SecondaryButton }
