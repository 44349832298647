import NavItem from "./NavItem"
import { Link } from "react-router-dom"
import ProgramLogo from "../../assets/images/ProgramLogo.png"
import globals from "../../constants/globals"
import { adminNavItems, consumerNavItems } from "../../constants/navItems"
import { useAuthStatus } from "../../hooks/useAuthStatus"
import type { IPermission, INavItem } from "../../types/permission"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import ResourcesNavItem from "./ResourcesNavItem"
import ReportsNavItems from "./ReportsNavItem"
import { useState } from "react"
import { CommonPaths, ConsumerPaths } from "../../constants/paths"
import {
  CommonPermissions,
  AdminPermissions,
  ConsumerPermissions,
} from "../../constants/permissions"
import BPSAdminNavItems from "./BPSAdminNavItem"
import UsersNavItems from "./UsersNavItems"
import UploaderNavItems from "./UploaderNavItem"
import ClaimsNavItems from "./ClaimsNavItem"
import CurrentIncentivesNavItem from "./CurrentIncentivesNavItem"
import CommunicationsNavItem from "./CommunicationsNavItem"

type Props = {
  showNone?: boolean
}

const { BRAND_LOGO_HEIGHT } = globals

const { SIGNIN_PATH, SIGNOUT_PATH } = CommonPaths

const { MY_ACCOUNT_PATH } = ConsumerPaths

const { VIEW_RESOURCES, VIEW_CURRENT_INCENTIVES } = CommonPermissions

const {
  VIEW_EDIT_CLAIM,
  VIEW_ENROLLMENT_REPORT,
  VIEW_CLAIM_REPORT,
  VIEW_DEALER_UPLOADER,
  VIEW_ENROLLMENT_LIST,
  VIEW_USERS_LIST,
  VIEW_CHANGE_ADDRESS_REPORT,
  VIEW_INVOICE_ORDER,
  VIEW_CLAIMS,
  VIEW_CLAIM_APPROVAL,
  VIEW_QUIZZES,
  VIEW_PROGRAM_COMMUNICATIONS,
} = AdminPermissions

const { VIEW_MY_ACCOUNT } = ConsumerPermissions
const { IS_ADMIN } = globals

const COLLAPSE = "collapse"

const Header = ({ showNone }: Props) => {
  const [collapse, setCollapse] = useState(COLLAPSE)
  const { permissions } = useGetMyPermissions()
  const { loggedIn } = useAuthStatus()

  const toggleCollapse = () => setCollapse(() => (collapse ? "" : COLLAPSE))

  return (
    <nav className="navbar navbar-expand-xl bg-white border-bottom">
      <div className="container-fluid">
        <Link className="navbar-brand" to={loggedIn ? "/" : SIGNIN_PATH}>
          <img
            src={ProgramLogo}
            height={BRAND_LOGO_HEIGHT}
            className="d-inline-block align-top"
            alt="Program logo"
          />
        </Link>

        {!showNone && (
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        )}

        <div className={`navbar-collapse justify-content-end ${collapse}`}>
          <ul className="navbar-nav" onClick={toggleCollapse}>
            {showNone ? null : (
              <>
                {loggedIn ? (
                  <>
                    {renderNavItems(
                      IS_ADMIN ? adminNavItems : consumerNavItems,
                      permissions
                    )}
                    {IS_ADMIN ? (
                      <>
                        {/* Uploader Dropdown */}
                        {[VIEW_DEALER_UPLOADER].some((permission) =>
                          permissions.includes(permission)
                        ) && (
                          <UploaderNavItems toggleCollapse={toggleCollapse} />
                        )}

                        {/* Current Incentives Dropdown */}
                        {[VIEW_CURRENT_INCENTIVES, VIEW_QUIZZES].some(
                          (permission) => permissions.includes(permission)
                        ) && (
                          <CurrentIncentivesNavItem
                            toggleCollapse={toggleCollapse}
                          />
                        )}

                        {/* Communications Dropdown */}
                        {[VIEW_PROGRAM_COMMUNICATIONS].some((permission) =>
                          permissions.includes(permission)
                        ) && (
                          <CommunicationsNavItem
                            toggleCollapse={toggleCollapse}
                          />
                        )}

                        {/* Claims Dropdown */}
                        {[VIEW_CLAIMS, VIEW_CLAIM_APPROVAL].some((permission) =>
                          permissions.includes(permission)
                        ) && <ClaimsNavItems toggleCollapse={toggleCollapse} />}

                        {/* Reports Dropdown */}
                        {[
                          VIEW_CLAIM_REPORT,
                          VIEW_ENROLLMENT_REPORT,
                          VIEW_CHANGE_ADDRESS_REPORT,
                        ].some((permission) =>
                          permissions.includes(permission)
                        ) && (
                          <ReportsNavItems toggleCollapse={toggleCollapse} />
                        )}

                        {/* Users Dropdown */}
                        {[VIEW_USERS_LIST, VIEW_ENROLLMENT_LIST].some(
                          (permission) => permissions.includes(permission)
                        ) && <UsersNavItems toggleCollapse={toggleCollapse} />}

                        {/* BPS Admin Dropdown */}
                        {[VIEW_EDIT_CLAIM, VIEW_INVOICE_ORDER].some(
                          (permission) => permissions.includes(permission)
                        ) && (
                          <BPSAdminNavItems toggleCollapse={toggleCollapse} />
                        )}
                      </>
                    ) : null}

                    {/* Resources Dropdown */}
                    {permissions.includes(VIEW_RESOURCES) && (
                      <ResourcesNavItem toggleCollapse={toggleCollapse} />
                    )}

                    {/* Consumer Side - My Account */}
                    {!IS_ADMIN && permissions.includes(VIEW_MY_ACCOUNT) && (
                      <NavItem to={MY_ACCOUNT_PATH} label="My Account" />
                    )}
                    <NavItem to={SIGNOUT_PATH} label="Sign Out" />
                  </>
                ) : (
                  <>
                    <NavItem to={SIGNIN_PATH} label="Sign In" />
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  )
}

const renderNavItems = (navItems: INavItem[], permissions: IPermission[]) =>
  navItems
    .filter((item: INavItem) => permissions.includes(item.permissionCode))
    .map((item, index) => (
      <NavItem to={item.path} label={item.label} end={item.end} key={index} />
    ))

export default Header
