import { ReactNode, useState } from "react"
import styled from "styled-components"
import heroImage from "../../assets/images/HeaderImage.jpg"
import globals from "../../constants/globals"

type HeroSectionContainerProps = {
  vh: number
}

const HeroSectionContainer = styled.div<HeroSectionContainerProps>`
  position: relative;
  img {
    width: 100%;
    @media screen and (min-width: 768px) {
      ${({ vh }) => vh >= 50 && `min-height: 450px;`}
      height: ${({ vh }) => `${vh}vh`};
      object-fit: cover;
    }
  }

  & > div {
    text-align: center;
    background-color: white;
    padding: 3rem;

    @media screen and (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 45%;
    }

    @media screen and (min-width: 992px) {
      max-width: 35%;
    }
  }
`

const Muted = styled.span`
  text-transform: uppercase;
  color: #555;
  font-weight: 600;
  font-size: 1rem;
`

const Heading = styled.h1`
  text-transform: uppercase;
  font-weight: 700;
`

const { IS_ADMIN } = globals

type IHeroSectionProps = {
  mutedText?: string
  heading?: string
  description?: ReactNode
  vh?: number
}

const HeroSection = ({
  mutedText,
  heading,
  description,
  vh,
}: IHeroSectionProps) => {
  const [showText, setShowText] = useState<boolean>(false)
  const toggleText = () => setShowText((val) => !val)
  return (
    <HeroSectionContainer vh={vh || 50}>
      <img src={heroImage} alt="Cover" onLoad={toggleText} />
      {showText && (
        <div>
          <Muted>{mutedText}</Muted>
          <Heading>{heading}</Heading>
          {IS_ADMIN && <h4>Administrator Module</h4>}
          {description}
        </div>
      )}
    </HeroSectionContainer>
  )
}

export default HeroSection
