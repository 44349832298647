import { Form, Input } from "antd"

type Props = { disabled?: boolean }

const PhoneNumber = ({ disabled }: Props) => {
  return (
    <>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please enter Phone number",
          },
          {
            pattern: /^[1-9][0-9]{9}$/,
            message: "Please enter 10 digits",
          },
        ]}
        name="phone"
        label="Phone"
      >
        <Input addonBefore={"+1"} autoComplete="new-password" disabled={disabled} />
      </Form.Item>
    </>

  )
}

export default PhoneNumber
