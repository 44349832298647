import { Form, Input } from "antd"

const City = () => {
  return (
    <Form.Item
      rules={[{ required: true, message: "Please enter a city name" }]}
      name="city"
      label="City"
    >
      <Input autoComplete="new-password" />
    </Form.Item>
  )
}

export default City
