import globals from "../../constants/globals"
import useGetProgramHeader from "../../hooks/useGetProgramHeader"
import HeroSection from "../styled/HeroSection.styled"

const { PROGRAM_NAME } = globals

const EnrollmentSuccess = () => {
  const { header } = useGetProgramHeader()

  return (
    <HeroSection
      mutedText={PROGRAM_NAME}
      heading="You Have Been Enrolled!"
      description={header}
    />
  )
}

export default EnrollmentSuccess
