import { List, Popconfirm } from "antd"
import { useState } from "react"
import { AnnouncementCard } from "../styled/CustomCard"
import HTMLReactParser from "html-react-parser"
import { useMutation, useQuery } from "@apollo/client"
import { GET_ANNOUNCEMENTS } from "../../graphql/queries"
import {
  IAnnouncement,
  IGetAnnouncementResponse,
} from "../../types/graphqlResponse"
import { DeleteFilled, EditFilled } from "@ant-design/icons"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import { AdminPermissions } from "../../constants/permissions"
import AnnouncementModal from "./AnnouncementModal"
import { DELETE_ANNOUNCEMENT } from "../../graphql/mutations"

const { DELETE_HOME_ANNOUNCEMENT, UPDATE_HOME_ANNOUNCEMENT } = AdminPermissions

const Announcements = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [itemToEdit, setItemToEdit] = useState<IAnnouncement>()
  const pageSize = 3

  const toggleModal = () => setIsModalOpen((val) => !val)

  const { permissions } = useGetMyPermissions()

  const { data, loading } = useQuery<IGetAnnouncementResponse>(
    GET_ANNOUNCEMENTS,
    {
      variables: {
        page: currentPage,
        limit: pageSize,
      },
    }
  )

  const [deleteAnnouncement] = useMutation(DELETE_ANNOUNCEMENT)

  const callDeleteMutation = (id: string) => {
    deleteAnnouncement({
      variables: { announcementId: id },
      refetchQueries: [
        { query: GET_ANNOUNCEMENTS, variables: { page: 1, limit: 3 } },
        "ListAnnouncementsWithPagination",
      ],
    })
  }

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const openModal = (item: IAnnouncement) => {
    setItemToEdit(item)
    toggleModal()
  }

  return (
    <>
      <List<IAnnouncement>
        loading={loading}
        dataSource={data?.listAnnouncementsWithPagination.announcements}
        pagination={{
          current: currentPage,
          total: data?.listAnnouncementsWithPagination.totalDocs,
          pageSize,
          onChange: changePage,
          showSizeChanger: false,
        }}
        split={false}
        renderItem={(item) => (
          <List.Item>
            <AnnouncementCard>
              <img src={item.image} alt="Announcement" />
              <div>
                <h5>{item.title}</h5>
                <span>{HTMLReactParser(item.body)}</span>
              </div>
              <div className="button-container">
                {/* Edit Announcement Button */}
                {permissions.includes(UPDATE_HOME_ANNOUNCEMENT) && (
                  <button type="button" onClick={() => openModal(item)}>
                    <EditFilled />
                  </button>
                )}
                {/* Delete Announcement Button */}
                {permissions.includes(DELETE_HOME_ANNOUNCEMENT) && (
                  <Popconfirm
                    title="Do you want to delete this announcement?"
                    description="Once deleted, it will not be available for reference in future."
                    onConfirm={() => callDeleteMutation(item._id)}
                    okText="Confirm"
                    okButtonProps={{ danger: true }}
                  >
                    <button type="button">
                      <DeleteFilled />
                    </button>
                  </Popconfirm>
                )}
              </div>
            </AnnouncementCard>
          </List.Item>
        )}
      />
      <AnnouncementModal
        open={isModalOpen}
        toggleModal={toggleModal}
        itemToEdit={itemToEdit}
      />
    </>
  )
}

export default Announcements
