import { INavItem } from "../types/permission"
import { ConsumerPaths, CommonPaths } from "./paths"
import {
  AdminPermissions,
  ConsumerPermissions,
  CommonPermissions,
} from "../constants/permissions"

const { VIEW_ADMIN_HOME } = AdminPermissions

const { VIEW_CONSUMER_HOME, VIEW_CLAIM_SALE } = ConsumerPermissions

const { VIEW_CURRENT_INCENTIVES } = CommonPermissions

const { CLAIM_SALE_PATH } = ConsumerPaths

const { CURRENT_INCENTIVES_PATH, HOME_PATH } = CommonPaths

const adminNavItems: INavItem[] = [
  {
    label: "Home",
    path: HOME_PATH,
    permissionCode: VIEW_ADMIN_HOME,
    end: true,
  },
]

const consumerNavItems: INavItem[] = [
  {
    label: "Home",
    path: HOME_PATH,
    permissionCode: VIEW_CONSUMER_HOME,
    end: true,
  },
  {
    label: "Current Incentives",
    path: CURRENT_INCENTIVES_PATH,
    permissionCode: VIEW_CURRENT_INCENTIVES,
  },
  {
    label: "Claim A Sale",
    path: CLAIM_SALE_PATH,
    permissionCode: VIEW_CLAIM_SALE,
  },
]

export { adminNavItems, consumerNavItems }
