import { Form, Input } from "antd"

const GoesByName = () => {
  return (
    <Form.Item name="goesByName" label="Goes By Name">
      <Input autoComplete="new-password" />
    </Form.Item>
  )
}

export default GoesByName
