import { useQuery } from "@apollo/client"
import { GET_MY_PERMISSIONS } from "../graphql/queries"
import { IGetMyPermissionsResponse } from "../types/graphqlResponse"
import { useAuthStatus } from "./useAuthStatus"

const useGetMyPermissions = () => {
  const { loggedIn } = useAuthStatus()
  const { data, loading, error, client } = useQuery<IGetMyPermissionsResponse>(
    GET_MY_PERMISSIONS,
    { skip: !loggedIn }
  )
  const permissions = data?.userPermissions || []

  return { permissions, loading, error, client }
}

export default useGetMyPermissions
