import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { removeAuthToken } from "../utils/auth"
import { useApolloClient } from "@apollo/client"
import { CommonPaths } from "../constants/paths"
import Header from "../components/Layout/Header"
import { Footer } from "antd/es/layout/layout"
import Container from "../components/styled/Container.styled"
import Spinner from "../components/Spinner"

const SignOut = () => {
  const navigate = useNavigate()
  const client = useApolloClient()

  useEffect(() => {
    removeAuthToken()
    client.resetStore()
    navigate(CommonPaths.SIGNIN_PATH)
  }, [navigate, client])

  return (
    <>
      <Header showNone />
      <Container>
        <Spinner />
      </Container>
      <Footer />
    </>
  )
}

export default SignOut
