import { Form, Input } from "antd"

const Address2 = () => {
  return (
    <Form.Item name="street2" label="Address 2">
      <Input autoComplete="new-password" />
    </Form.Item>
  )
}

export default Address2
