import { Link } from "react-router-dom"
import { AdminPaths, CommonPaths } from "../../constants/paths"
import {
  AdminPermissions,
  CommonPermissions,
} from "../../constants/permissions"
import DropdownNavbar from "./DropdownNavbar"

const { VIEW_CURRENT_INCENTIVES } = CommonPermissions

const { VIEW_QUIZZES } = AdminPermissions
const { CURRENT_INCENTIVES_PATH } = CommonPaths
const { QUIZZES_PATH } = AdminPaths

const items = [
  {
    label: <Link to={CURRENT_INCENTIVES_PATH}>Product Incentives</Link>,
    permission: VIEW_CURRENT_INCENTIVES,
    key: "1",
  },
  {
    label: <Link to={QUIZZES_PATH}>Quizzes & Surveys</Link>,
    permission: VIEW_QUIZZES,
    key: "2",
  },
]

type CurrentIncentivesNavItemProps = {
  toggleCollapse: () => void
}

const CurrentIncentivesNavItem = ({
  toggleCollapse,
}: CurrentIncentivesNavItemProps) => {
  return (
    <DropdownNavbar
      label="Current Incentives"
      items={items}
      toggleCollapse={toggleCollapse}
    />
  )
}

export default CurrentIncentivesNavItem
