import { Form, Input } from "antd"

type Props = { disabled?: boolean }

const LegalFirstName = ({ disabled }: Props) => {
  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: "Please enter your legal first name",
        },
      ]}
      name="firstName"
      label="Legal First Name"
    >
      <Input autoComplete="new-password" disabled={disabled} />
    </Form.Item>
  )
}

export default LegalFirstName
