import { useState } from "react"
import { Checkbox } from "antd"
import { checkBoxProp } from "../../../types/reports"
import type { CheckboxChangeEvent } from "antd/es/checkbox"
import styled from "styled-components"

const GridView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 2fr 2fr 1fr;

  @media (max-width: 1280px) {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 2fr;
    grid-template-rows: 4fr 4fr 4fr 2fr;
  }

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr;
    grid-template-rows: 3fr 3fr 3fr 3fr 2fr;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 3fr 3fr;
    grid-template-rows: 5fr 5fr 5fr 5fr 5fr 5fr 2fr;
  }

  @media (max-width: 425px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 2fr;
  }
`

const CheckReport = (props: checkBoxProp) => {
  const [checkedAll, setCheckedAll] = useState(props.checkBoxList)

  const onChange = (e: CheckboxChangeEvent) => {
    const { value, checked } = e.target
    if (value === "selectAll") {
      if (checked) {
        let selectAll = checkedAll.map((check) => {
          return { ...check, isChecked: checked }
        })
        setCheckedAll(selectAll)
        let selectedValArr = selectAll.map((check) => check.value)
        props.selected(selectedValArr)
      } else {
        let disSelectAll = checkedAll.map((check) => {
          return { ...check, isChecked: false }
        })

        let removeSelection = disSelectAll
          .filter((check) => check.isChecked === true)
          .map((select) => {
            return { ...select }
          })

        setCheckedAll(disSelectAll)
        props.selected(removeSelection)
      }
    } else {
      let singleSelect = checkedAll.map((check) => {
        return check.value === value ? { ...check, isChecked: checked } : check
      })
      let getSingleSelect = singleSelect.filter((check) => {
        return !!check.isChecked ? check.value : null
      })

      let getSingleSelectValue = getSingleSelect.map((check) => {
        return check.value
      })

      setCheckedAll(singleSelect)
      props.selected(getSingleSelectValue)
    }
  }

  return (
    <>
      <Checkbox
        onChange={onChange}
        value={"selectAll"}
        checked={
          checkedAll.filter((checkbox) => checkbox?.isChecked !== true).length <
          1
        }
      >
        Select All
      </Checkbox>

      <div className="row mt-3">
        <div className="col-md-12">
          <GridView>
            {checkedAll.map((checkList, index) => {
              return (
                <Checkbox
                  className="mx-0"
                  onChange={onChange}
                  checked={checkList?.isChecked || undefined}
                  value={checkList.value}
                  key={index}
                >
                  {checkList.label}
                </Checkbox>
              )
            })}
          </GridView>
        </div>
      </div>
    </>
  )
}

export default CheckReport
