import Layout from "../components/Layout/Layout"
import Home from "../pages/Home"
import commonRoutes from "./CommonRoutes"
import AuthorisedRoute from "../components/AuthorisedRoute"
import { useRoutes } from "react-router-dom"
import { useMemo } from "react"
import { ConsumerPermissions } from "../constants/permissions"
import type { IRoute, IParentRoute } from "../types/route"
import { CommonPaths, ConsumerPaths } from "../constants/paths"
import useGetMyPermissions from "../hooks/useGetMyPermissions"
import { lazy } from "react"
import UpdatePassword from "../pages/UpdatePassword"
import SignIn from "../pages/SignIn"
import SignOut from "../pages/SignOut"
import EnrollmentForm from "../pages/consumer/EnrollmentForm"
import NotFound from "../pages/NotFound"
import ForgotPassword from "../pages/ForgotPassword"

const ClaimSale = lazy(() => import("../pages/consumer/ClaimSale"))
const MyAccount = lazy(() => import("../pages/consumer/MyAccount"))
const MyAccountClaimTable = lazy(
  () => import("../pages/consumer/MyAccountClaimTable")
)
const Profile = lazy(() => import("../pages/consumer/Profile"))
const Inbox = lazy(() => import("../pages/consumer/Inbox"))

const {
  VIEW_CONSUMER_HOME,
  VIEW_CLAIM_SALE,
  VIEW_MY_ACCOUNT,
  VIEW_MY_CLAIMS,
  VIEW_MY_PROFILE,
  VIEW_INBOX,
} = ConsumerPermissions

const {
  CLAIM_SALE_PATH,
  MY_ACCOUNT_PATH,
  ENROLLMENT_FORM_PATH,
  MY_PROFILE_PATH,
  MY_INBOX_PATH,
} = ConsumerPaths

const {
  SIGNIN_PATH,
  SIGNOUT_PATH,
  UPDATE_PASSWORD_PATH,
  FORGOT_PASSWORD_PATH,
  HOME_PATH,
} = CommonPaths

const ConsumerRoutes = () => {
  const { permissions } = useGetMyPermissions()

  const consumerPrivateRoutes: IRoute[] = [
    {
      element: <Home />,
      path: HOME_PATH,
      permissionCode: VIEW_CONSUMER_HOME,
    },
    {
      element: <ClaimSale />,
      path: CLAIM_SALE_PATH,
      permissionCode: VIEW_CLAIM_SALE,
    },
    {
      element: <MyAccount />,
      path: MY_ACCOUNT_PATH,
      permissionCode: VIEW_MY_ACCOUNT,
      children: [
        {
          path: MY_ACCOUNT_PATH,
          element: <MyAccountClaimTable />,
          permissionCode: VIEW_MY_CLAIMS,
        },
        {
          path: MY_PROFILE_PATH,
          element: <Profile />,
          permissionCode: VIEW_MY_PROFILE,
        },
        {
          path: MY_INBOX_PATH,
          element: <Inbox />,
          permissionCode: VIEW_INBOX,
        },
      ].filter((route: IRoute) => permissions.includes(route.permissionCode)),
    },
    ...commonRoutes,
  ]

  const filteredConsumerPrivateRoutes = consumerPrivateRoutes.map(
    (route: IRoute) =>
      permissions.includes(route.permissionCode)
        ? route
        : {
            ...route,
            element: <NotFound />,
            children: undefined,
          }
  )

  const consumerRoutes: IParentRoute = useMemo(
    () => [
      {
        element: <Layout />,
        children: [
          {
            element: <AuthorisedRoute />,
            children: filteredConsumerPrivateRoutes,
          },
        ],
      },
      { element: <SignOut />, path: SIGNOUT_PATH },
      { element: <SignIn />, path: SIGNIN_PATH },
      { element: <EnrollmentForm />, path: ENROLLMENT_FORM_PATH },
      { element: <UpdatePassword />, path: UPDATE_PASSWORD_PATH },
      { element: <ForgotPassword />, path: FORGOT_PASSWORD_PATH },
      { element: <NotFound />, path: "*" },
    ],
    [filteredConsumerPrivateRoutes]
  )

  return useRoutes(consumerRoutes)
}

export default ConsumerRoutes
